<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #cmslefttype(vaddress-listitem)="{data: d}">
      <list-item :data="d.Data">
        <template #links>
          <div class="grid grid-cols-2 gap-4 mt-4">
            <router-link v-for="(l, i) in d.Data.Links" :to="l.To" :key="i">
              <b-button class="w-full" :type="l.Type">{{l.Text}}</b-button>
            </router-link>
          </div>
        </template>
      </list-item>
    </template>
  </page>
</template>

<script>
import CMSPage from './base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    ListItem: () => import('@/components/vaddress/ListItem')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (vm.isMd) {
        vm.$router.replace({ name: 'login' })
      }
    })
  }
}
</script>

<style>

</style>
